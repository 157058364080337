import { type GetStaticProps } from 'next';

import { EventContainer, type EventsContainerProps } from '~/v1/containers/events/events';
import { getEvents } from '~/v1/graphql/actions/server/getEvents';
import { getTheme } from '~/v1/graphql/actions/server/getTheme';
import { Layout } from '~/v1/system/layout/layout';

type EventsPageProps = EventsContainerProps;

const EventsPage: React.FC<EventsPageProps> = ({ data }) => {
  return (
    <Layout
      navigation={data.mainNavigation}
      featuredSearches={data.featuredSearches}
      footer={data.footer}
    >
      <EventContainer data={data} />
    </Layout>
  );
};

export default EventsPage;

export const getStaticProps: GetStaticProps<EventsPageProps> = async () => {
  const theme = await getTheme();
  const { data } = await getEvents();

  if (!data.eventsPage) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      data,
      theme,
    },
  };
};
