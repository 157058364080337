import Head from 'next/head';

import { AnalyticsModule } from '~/ui/components/analytics';
import { type Events } from '~/v1/_types/Events';
import { type EventsQuery } from '~/v1/_types/EventsQuery';
import { Filter } from '~/v1/components/filter/filter';
import { FILTER_CONFIG } from '~/v1/components/filter/filter.config';
import { FilterManager } from '~/v1/components/filter/filter.manager';
import { getEventsFilter } from '~/v1/graphql/actions/client/getEventsFilter';
import { EmphasizedModule } from '~/v1/modules/emphasized/emphasized';
import { HeaderModule } from '~/v1/modules/header';

import styles from './events.module.scss';
import { EventsFiltersSection } from './filters/filters';
import { UpcomingEventsSection } from './upcomingEvents/upcomingEvents';

export interface EventsContainerProps {
  data: EventsQuery;
}

export function EventContainer({ data }: EventsContainerProps) {
  const { title, description, newsletter, featuredEvent, upcomingEvents } =
    data.eventsPage as Events;

  return (
    <>
      <Head>
        <title>Watch Events Hosted by the Mellon Foundation</title>
        <meta name="description" content={description} />
      </Head>

      <HeaderModule title={title} description={description} isLandingPage isRootPage />

      <UpcomingEventsSection newsletter={newsletter} upcomingEvents={upcomingEvents} />

      {featuredEvent && (
        <EmphasizedModule
          gtm={{ module: AnalyticsModule.FEATURED_EVENT }}
          cardClass={styles.emphasized}
          className={styles.featured}
          title="Past events"
          isEventsPage
          data={featuredEvent}
        />
      )}

      <FilterManager api={getEventsFilter} config={FILTER_CONFIG.EVENTS}>
        <Filter data={data.events.entities} total={data.events.totalCount}>
          <EventsFiltersSection />
        </Filter>
      </FilterManager>
    </>
  );
}
