import {
  type EventsFilterQuery,
  type EventsFilterQueryVariables,
} from '~/v1/_types/EventsFilterQuery';
import { type FilterQueryResult } from '~/v1/components/filter/filter.interface';
import { client } from '~/v1/graphql/client';
import { EVENTS_FILTER_QUERY } from '~/v1/graphql/queries/eventsFilter';

export const getEventsFilter = async (
  variables: EventsFilterQueryVariables,
): Promise<FilterQueryResult> => {
  const { data } = await client.query<EventsFilterQuery>({
    query: EVENTS_FILTER_QUERY,
    variables,
  });

  return {
    data: data.events.entities,
    count: data.events.totalCount,
  };
};
