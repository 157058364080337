import { gql } from '@apollo/client';

import { EVENTS_RESULTS_FRAGMENT } from '../fragments/eventResults';

export const EVENTS_FILTER_QUERY = gql`
  query EventsFilterQuery($limit: Int!, $offset: Int!) {
    events(limit: $limit, offset: $offset) {
      ...EventsResults
    }
  }
  ${EVENTS_RESULTS_FRAGMENT}
`;
