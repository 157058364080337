import { gql } from '@apollo/client';

import { ARTICLE_CARD_FRAGMENT } from './cards/article';

export const EVENTS_RESULTS_FRAGMENT = gql`
  fragment EventsResults on EventsResults {
    entities {
      ...ArticleCard
    }
    totalCount
  }
  ${ARTICLE_CARD_FRAGMENT}
`;
